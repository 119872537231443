import "./styles.css";
import "./styles.scss";
import React, { useState, useRef, Suspense, useEffect } from "react";
import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import CountUp from "react-countup";
import AOS from "aos";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";
import "../node_modules/aos/dist/aos.css";
import { useInView } from "react-hook-inview";
// import Parallax, { Layer, AppearLayer } from "react-parallax-scroll";
import Footer from "./Footer";
import Header from "./Header";
import MainDropdown from "./MainDropdown";
import Socials from "./Socials";

AOS.init({
  once: true
});

const HomeScreen = ({ setScreen, screen }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const RenderGlobe = () => {
    let totalOrbs = 800;
    const orbs = [];
    for (let x = 0; x < totalOrbs; x++) {
      orbs.push(<div className="c" />);
    }
    return orbs;
  };

  const Home = () => {
    return (
      <div className="section home">
        <div className="inner">
          <div className="wrap">{RenderGlobe()}</div>
          {/*
          <div
            className="tiltContainer"
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay="500"
          >
            <Layer settings={{ speed: -0.2, type: "translateY" }}>
              <Tilt
                glareEnable={true}
                glareMaxOpacity={"0.03"}
                glareBorderRadius={"50%"}
                glarePosition={"all"}
                trackOnWindow={true}
              >
                <img
                  className="homeIMG"
                  src="https://i.ibb.co/TWfZsGv/New-Project-2022-04-12-T184953-531.png"
                  alt=""
                />
              </Tilt>
            </Layer>
          </div>*/}


          <div className="innerhome">
            <div
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="500"
            >
              <p>
                <span className="blue">A</span>lmost <span className="blue">S</span>urely<br/>
                <span className="blue">I</span>nnovate<br/>
                <span className="blue">S</span>ociety and <span className="blue">T</span>echnology
              </p>
            </div>
            <h2
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="800"
            >
              <span className="font-size-2">
              <span className="companyName">a.s.ist</span>  <span className="blue">inc.</span><br/>
              </span>
            </h2>
            {/* <button
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="1000"
              data-aos-offset="-100"
              onClick={() => {
                setScreen("forms");
                scroll.scrollToTop();
              }}
            >
              Verify Today
            </button> */}
          </div>
        </div>

      
      </div>

    
    );
  };

  const About = () => {
    return (
      <div className="section about" id="about">
        <div className="inner">
          <div>
            <div className="flex">
              <p
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="000"
              >
                <span className="blue">技術の力で社会を変革する </span>
              </p>
              <img
                data-aos="fade"
                data-aos-duration="15000"
                data-aos-delay="000"
                className="lawfirm2"
                src="asist_logo.png"
                alt=""
              />
            </div>
            <h1
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="000"
            >
              <span className="font-size-1">
                株式会社 a.s.istは"Almost Surely Innovate Society and Technology"という理念を掲げ、その頭文字をとった社名になっています．

                "Almost Surely"とは確率論で用いられる「ほとんど確実に」という用語で「確率1で」という意味です．

                テクノロジーの力を用いて確率1で今までよりも生きやすい社会へと変えるという強い意志を持って様々なことに挑戦していきます．
                </span> 
                </h1>
            <br></br>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay="500"
          >
            {/* <Layer settings={{ speed: -0.1, type: "translateX" }}>
              <img
                className="lawfirm"
                src="https://i.ibb.co/F0Rsdtk/Law-Firm-Law-Office-Lawyer-services-Vector-logo-template-on-transparent-PNG.png"
                alt=""
              />
            </Layer> */}
          </div>
        </div>
      </div>
    );
  };

  const Services = () => {
    return (
      <div className="section services" id="services">
        <div className="inner">
          <div>
            <h5
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="00"
            >
              <span className="blue">Our services</span> 
            </h5>
            <h5
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="00"
            >
              <span className="font-size-1">
              ベイズ推論や深層学習を用いたデータ解析やアルゴリズムの提供を行っています。
              </span>
            </h5>
          </div>
          <div className="cardsContainer">
            <div className="leftSection">
              <div
                className="container"
                data-aos="fade-down-right"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>事業のご紹介</h2>
                  <picture>
                  {/* <source srcset="https://a-s-ist.github.io/a.s.ist.github.io/images/services1.png" media="(max-width: 800px)"/>
                  <img src="https://a-s-ist.github.io/a.s.ist.github.io/images/services1.png" alt="img"/> */}
                  <source srcset="images/services1.png" media="(max-width: 800px)"/>
                  <img src="images/services1.png" alt="img"/>
                  </picture>
                  {/* <h3>
                  モデルのデータに対する妥当性を定量的に評価し選択する．
                  </h3> */}
                  <Link
                    activeClass="active"
                    to="verify"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  >
                    {/*
                    <button
                      onClick={() => {
                        setScreen("forms");
                        scroll.scrollToTop();
                      }}
                    >
                      Submit a Project
                    </button>
                    */}
                  </Link>
                </div>
                {/*
                <div className="cardBack">
                  <button>Submit a Project</button>
                </div>
                */}
              </div>
              </div>
              <div className="leftSection">
              <div
                className="container"
                data-aos="fade-down-left"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>ご納品までの流れ</h2>
                  <picture>
                  <source srcset="https://a-s-ist.github.io/a.s.ist.github.io/images/services2.png" media="(max-width: 800px)"/>
                  <img src="https://a-s-ist.github.io/a.s.ist.github.io/images/services2.png" alt="img"/>
                  </picture>
                  {/* <h3>
                  モデルパラメータを分布と推定し，信頼度を定量的に評価する
                  </h3> */}
                  {/* <Link
                    activeClass="active"
                    to="verify"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  >
                    
                    <button
                      onClick={() => {
                        setScreen("forms");
                        scroll.scrollToTop();
                      }}
                    >
                      Submit a Project
                    </button>
                   
                  </Link> */}
                </div>                
                {/* <div className="cardBack">
                  <button>Submit a Project</button>
                </div> */}
              </div>
            </div>
            <div className="rightSection">
              <div
                className="container"
                data-aos="fade-up-right"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>ベイズ推論</h2>
                  <picture>
                  <source srcset="https://a-s-ist.github.io/a.s.ist.github.io/images/beysian_selection_mobile.png" media="(max-width: 800px)"/>
                  <img src="https://a-s-ist.github.io/a.s.ist.github.io/images/beysian_selection.png" alt="img"/>
                  </picture>
                  {/* <h3>
                  解析精度向上等のために，ベイズ的に複数の計測データを統合する．
                  </h3> */}
                  {/* <Link
                    activeClass="active"
                    to="verify"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  >
                    
                    <button
                      onClick={() => {
                        setScreen("forms");
                        scroll.scrollToTop();
                      }}
                    >
                      Submit a Project
                    </button>
                   
                  </Link> */}
                </div>
                {/*
                <div className="cardBack">
                  <button>Submit a Project</button>
                </div>
                */}
              </div>
            </div>
            <div className="rightSection">
              <div
                className="container"
                data-aos="fade-up-left"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>深層学習</h2>
                  <picture>
                  <source srcset="https://a-s-ist.github.io/a.s.ist.github.io/images/deep_learning_mobile.png" media="(max-width: 800px)"/>
                  <img src="https://a-s-ist.github.io/a.s.ist.github.io/images/deep_learning.png" alt="img"/>
                  </picture>
                  {/* <h3>Coming soon...</h3> */}
                </div>
                {/*
                <div className="cardBack">
                  <button>Learn More</button>
                </div>
                */}
              </div>
            </div>
            
            {/* ＝＝＝＝＝＝＝＝＝＝＝＝コンテンツ追加用＝＝＝
            <div className="addSection">
              <div
                className="container"
                data-aos="fade-up-right"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>ベイズ統合</h2>
                  <img src="https://a-s-ist.github.io/a.s.ist.github.io/images/beysian_integration.png" alt="img"/>
                  <h3>
                  Coming soon...
                  </h3>
                </div>
              </div>
              <div
                className="container"
                data-aos="fade-up-left"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>フゴフゴ量子アニーリング</h2>
                  <h3>Coming soon...</h3>
                </div>
              </div>
            </div> */}
          
          </div>
        </div>
      </div>
    );
  };

  const Metrics = () => {
    const [ref, isVisible] = useInView({
      threshold: 0
    });
    const RenderMetrics = () => {
      return (
        <div className="section metrics">
          <div className="inner">
            <div className="container">
            <div className="flex">
                <div
                  className="innerContainer"
                  data-aos="fade"
                  data-aos-duration="2000"
                  data-aos-delay="000"
                >
                  {/* <CountUp
                    start={0}
                    end={1}
                    duration={1.3}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=""
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp> */}
                  <div className="innerContainer">
                  <a href="http://www.mi.u-tokyo.ac.jp/contest2023.html" target="_blank">
                  <img src="images/f0923_3-removebg-preview.png" sizes="200"></img>
                  </a>
                  </div>
                  {/* <h4>東京大学　数理・データサイエンスコンソーシアム</h4><br/>
                  <h4>第６回 データサイエンスコンテスト 最優秀賞</h4><br/> */}
                </div>
              </div>

              {/* <div className="flex">
                <div
                  className="innerContainer"
                  data-aos="fade"
                  data-aos-duration="2000"
                  data-aos-delay="000"
                >
                  <CountUp
                    start={0}
                    end={15}
                    duration={1.3}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=" YR"
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  <h4>Professional Experience</h4>
                </div>
                <div
                  className="innerContainer"
                  data-aos="fade"
                  data-aos-duration="2000"
                  data-aos-delay="200"
                >
                  <CountUp
                    start={0}
                    end={250}
                    duration={1.4}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix="$"
                    suffix="k"
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  <h4>Pending Recoveries</h4>
                </div>
              </div>
              <div className="flex">
                <div
                  className="innerContainer"
                  data-aos="fade"
                  data-aos-duration="2000"
                  data-aos-delay="400"
                >
                  <CountUp
                    start={0}
                    end={40}
                    duration={1.5}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix="$"
                    suffix="k"
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  <h4>Recovered</h4>
                </div>
                <div
                  className="innerContainer"
                  data-aos="fade"
                  data-aos-duration="2000"
                  data-aos-delay="600"
                >
                  <CountUp
                    start={0}
                    end={10}
                    duration={1.6}
                    separator=" "
                    decimals={0}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <h2 ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  <h4>Projects Reviewed</h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    };
    return (
      <div ref={ref}>{isVisible ? <RenderMetrics /> : <RenderMetrics />}</div>
    );
  };

  const Members = () => {
    return (
      <div className="section members" id="members">
        <div className="inner">
          <h1
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-delay="000"
          >
            <span className="blue">Our Members</span>
          </h1>
          <div className="container">
            <div className="row">
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="000"
              >
                <a href="http://www.yuihayashi.com/" target="_blank">
                  <img
                    src={
                      "https://a-s-ist.github.io/a.s.ist.github.io/images/hayashi.png"
                    }
                    alt="img"
                  />
                </a>
                林 悠偉<br/>
                CEO<br/>
                <div className="introduction">
                スタートアップで3年間インターンとして機械学習の研究・開発に携わった後,現職.
                大学院では<a href="https://journals.jps.jp/doi/10.7566/JPSJ.92.094002">小角散乱データにおけるベイズ的解析手法の研究</a>を行う.
                極真空手1級で, 大会で3度の表彰台を経験. 書道7段. 趣味は虫取り.   
                </div>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="100"
              >
                <a target="_blank">
                  <img
                    src={
                      "https://a-s-ist.github.io/a.s.ist.github.io/images/moriguchi.png"
                    }
                    alt="img"
                  />
                </a>
                森口 椋太<br/>
                CTO<br/>
                <div className="introduction">
                株式会社カラクリにて, 自然言語の表現学習などの研究開発に従事.
                大学院では<a href="https://journals.jps.jp/doi/full/10.7566/JPSJ.91.104002">メスバウアースペクトルにおけるベイズ的解析の研究</a>を行う.
                未踏2023年度にてファッション×AIのプロダクトの研究開発に従事.サウナー.
                </div>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="200"
              >
                <a target="_blank">
                  <img
                    src={
                      "https://a-s-ist.github.io/a.s.ist.github.io/images/nabika.png"
                    }
                    alt="img"
                  />
                </a>
                竝河 伴裕<br/>
                CRO<br/>
                <div className="introduction">
                東京大学計数工学科卒. PFN等の会社でインターンとしてMIの研究に従事した後, 現職.
                大学院での研究テーマはベイズ推論を用いた能動学習による計測実験手法の開発.
                ’23年度千葉県フットサルリーグ1部得点王.
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="400"
              >
                <a target="_blank">
                  <img
                    src={
                      "https://a-s-ist.github.io/a.s.ist.github.io/images/shioya.png"
                    }
                    alt="img"
                  />
                </a>
                塩谷 佳介<br/>
                DS<br/>
                <div className="introduction">
                ヤフー株式会社でのインターンシップで, データサイエンスを用いた検索補助機能の改善に従事.
                大学院ではニューロンのスパイクデータ解析から, 視覚情報処理メカニズムの解明を目指す.
                趣味: 野球観戦（カープ）
                </div>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                <a target="_blank">
                  <img
                    src={
                      "https://a-s-ist.github.io/a.s.ist.github.io/images/shimizu.png"
                    }
                    alt="img"
                  />
                </a>
                清水 将海<br/>
                DS<br/>
                <div className="introduction">
                株式会社 a.s.istでは機械学習やLLMを用いたデータ解析に従事.
                大学院では機械学習を利用して脳のカテゴリー情報処理の研究を行う.
                趣味は美味しいものを食べに行くことと散歩.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const Partners = () => {
    return (
      <div className="section partners">
        <div className="inner">
          <h1
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-delay="000"
          >
            Our Partners
          </h1>
          <div className="container">
            <div className="row">
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="000"
              >
                <a href="https://www.gb.law/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/h23kXwJ/New-Project-2022-04-11-T185429-532.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="100"
              >
                <a href="https://www.peifferwolf.com/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/3YVDjLH/New-Project-2022-04-11-T185456-056.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="200"
              >
                <a href="https://www.chainalysis.com/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/w0cK4t3/New-Project-2022-04-11-T185433-675.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="300"
              >
                <a href="https://unicrypt.network/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/sJkwtwt/New-Project-2022-04-11-T185437-681.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="400"
              >
                <a href="https://darkhorseintel.com/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/1M04P4Y/New-Project-2022-04-11-T185442-707.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="500"
              >
                <a href="https://guidepostsolutions.com/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/m6q9QyW/New-Project-2022-04-11-T185446-692.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
              <div
                className="innerContainer"
                data-aos="fade"
                data-aos-duration="2000"
                data-aos-delay="600"
              >
                <a href="https://tornado.cash/" target="_blank">
                  <img
                    src={
                      "https://i.ibb.co/vXPN9R8/New-Project-2022-04-11-T185450-033.png"
                    }
                    alt="img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Loading = () => {
    if (loading === true) {
      return (
        <div className="loading">
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
        </div>
      );
    } else {
      return (
        <>
          <div className="doneLoadingBG" />
          <div className="doneLoadingLeft" />
          <div className="doneLoadingRight" />
        </>
      );
    }
  };

  return (
    <div className="App">
      {/* <Parallax> */}
        {/* <div className="mainSocials">
          <Socials />
        </div> */}
        <Loading />
        <Home />
        <About />
        <Metrics />
        <Services />
        <Members />
        <Footer />
      {/* </Parallax> */}
    </div>
  );
};
export default HomeScreen;
