import { Link, animateScroll as scroll } from "react-scroll";
import { useState } from "react";
import Socials from "./Socials";
import Button from '@mui/material/Button';

const MainDropdown = ({ setScreen, screen }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <div className="mobileNav">
        <div
          className="inner"
          data-aos="fade"
          data-aos-duration="1500"
          data-aos-delay="00"
        >
          <div
            className="logo"
            onClick={() => {
              setScreen("home");
              scroll.scrollToTop();
            }}
          >
            <img src="https://a-s-ist.github.io/a.s.ist.github.io/asist_logo.png" alt="logo" height="50" width="50"/>
            {/* <svg
              preserveAspectRatio="xMidYMid meet"
              data-bbox="20.303 25.565 159.393 148.871"
              viewBox="20.303 25.565 159.393 148.871"
              height="200"
              width="200"
              xmlns="asist_logo.png"
              data-type="color"
              role="img"
              aria-labelledby="svgcid--jebrxrywnvug"
            >
              <g>
                <path
                  d="M179.398 97.035c-2.089-12.835-17.014-19.402-28.953-14.029l-12.537 5.671c-11.94 5.373-25.371 5.373-37.311 0L88.06 83.006C76.12 77.633 61.196 84.2 59.107 97.035c-.299.895-.299 2.089-.299 3.283 0 .895 0 2.089.299 3.283 2.089 12.835 17.014 19.402 28.953 14.029l12.537-5.671c11.94-5.373 25.371-5.373 37.311 0l12.537 5.671c11.94 5.373 26.864-1.194 28.953-14.029.298-.895.298-2.089.298-3.283 0-.895 0-2.089-.298-3.283z"
                  fill="#FD6262"
                  data-color="1"
                ></path>
                <path
                  d="M49.556 62.112l12.537-5.671c11.94-5.373 25.371-5.373 37.311 0l12.537 5.671c11.94 5.373 26.864-1.194 28.953-14.029.298-.895.298-2.089.298-3.283 0-.895 0-2.089-.298-3.283-2.089-12.835-17.014-19.402-28.953-14.029l-12.537 5.671c-11.94 5.373-25.371 5.373-37.311 0l-12.537-5.671c-11.94-5.373-26.864.895-28.953 14.029-.299.895-.299 2.089-.299 3.283 0 .896 0 2.089.299 3.283 2.089 12.835 17.013 19.402 28.953 14.029z"
                  fill="#FD6262"
                  data-color="1"
                ></path>
                <path
                  d="M111.94 137.928l-12.537 5.671c-11.94 5.373-25.371 5.373-37.311 0l-12.537-5.671c-11.94-5.373-26.864 1.194-28.953 14.029-.299.895-.299 2.089-.299 3.283 0 .896 0 2.089.299 3.283 2.089 12.835 17.014 19.402 28.953 14.029l12.537-5.671c11.94-5.373 25.371-5.373 37.311 0l12.537 5.671c11.94 5.373 26.864-1.194 28.953-14.029.298-.896.298-2.089.298-3.283 0-.895 0-2.089-.298-3.283-2.089-12.836-17.014-19.402-28.953-14.029z"
                  fill="#FD6262"
                  data-color="1"
                ></path>
              </g>
            </svg> */}
            <span className="companyName"><h1>a.s.ist</h1></span>
            <h1 className="blue"> inc.</h1>
          </div>
          <div
            className={`buttonWrapper ${dropdown ? "open" : "closed"}`}
            onClick={() => setDropdown(!dropdown)}
          >
            <div className="hamburger2"></div>
          </div>
        </div>
      </div>
      <div className="mainDropdown">
        {dropdown ? (
          <div className="drop">
            <div className="sidebar">
              <div className="innerSidebar">
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <Button variant="contained" color="primary" onClick={() => {
                      setDropdown(false);
                      setScreen("home");
                    }}>About Us</Button>
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}                 
                >
                  <Button variant="contained" color="primary" onClick={() => {
                      setDropdown(false);
                      setScreen("home");
                    }}>Services</Button>
                </Link>
                <Link
                  activeClass="active"
                  to="members"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                <Button variant="contained" color="primary" onClick={() => {
                  setDropdown(false);
                  setScreen("home");
                }}>Members</Button>
                </Link>
                {/* <Link
                  activeClass="active"
                  to="verify"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  <button
                    onClick={() => {
                      setDropdown(false);
                      setScreen("forms");
                    }}
                  >
                    Verify Today
                  </button>
                </Link> */}
              </div>
              {/* <Socials /> */}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default MainDropdown;
